import "../scss/stage.scss";
import {plugin_registry, PluginBase} from "nk-plugin-registry";

@plugin_registry.register("Stage")
class Stage extends PluginBase {
  constructor($node) {
    super($node);
  }

  loaded($node) {
      super.loaded($node);
  }
}
