import "scss/index.scss";

import { plugin_registry } from 'nk-plugin-registry';
// import your plugins here.

import "generics/references";

import "citation/js/citation";
import "header/js/header.js";
import "footer/js/footer.js";
import "youtube_video/js/youtube_video.js";
import "simple_stage/js/stage.js";
import "../../archive/js/archive.js";


plugin_registry.init();

